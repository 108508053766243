.list-view {
    background: white;
    height: calc(100vh - 137px);
    overflow-y: auto;
    padding: 0 60px;
}

.list-container {
    padding: 20px 40px;
    margin: 0 auto;
}

.date-section {
    margin-bottom: 30px;
}

.date-section h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
}

.task-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;
    border-bottom: 1px solid #E0E0E0;
}

.task-left {
    display: flex;
    /* align-items: center; */
    gap: 12px;
}

.task-right {
    display: flex;
    align-items: center;
    gap: 20px;
}

.checkbox {
    width: 20px;
    height: 20px;
    accent-color: #8338EC;
    margin-top: 15px;
}

.time {
    color: #8B898D;
    font-size: 14px;
}

.class-tag {
    background: #F3F3F3;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    color: #333;
}