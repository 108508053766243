/* Popup Container */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 1;
}

/* Popup Content */
.popup-content {
    
    background-color: #fff;
    border: 2px solid #8338ec;
    border-radius: 20px;
    padding: 20px 30px;
    width: 400px;
    max-width: 90%;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

/* Popup Title */
.popup-title {
    font-size: 24px;
    font-weight: bold;
    color: #8338ec;
    border: none;
    background: transparent;
    outline: none;
    margin-bottom: 10px;
    width: 100%;
}

.popup-title:focus {
    border-bottom: 2px solid #8338ec;
}

/* Fields and Labels */
.popup-fields {
    margin: 10px 0;
}
.popup-row {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #666;
}
.popup-pill {
    background-color: #f3e8ff;
    color: #8338ec;
    border: 1px solid #8338ec;
    border-radius: 20px;
    padding: 5px 10px;
    font-size: 12px;
}
.popup-label {
    display: block;
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
}
.popup-textarea {
    width: 100%;
    height: 80px;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    font-size: 14px;
    resize: none;
}

/* Save Button */
.popup-save-btn {
    display: block;
    width: 100%;
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(90deg, #8338ec, #bfa1e9);
    border: none;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 15px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}
.popup-save-btn:hover {
    background: linear-gradient(90deg, #a775f0, #e3d5ff);
    transform: scale(1.02);
}
.popup-save-btn:active {
    transform: scale(0.98);
}
