.welcome {
    border-radius: 45px;
    background: radial-gradient(123.37% 145% at 29.41% 3.92%, #8338EC 49.11%, #BFA1E9 96.5%);
    box-shadow: 8px 11px 4px 0px rgba(0, 0, 0, 0.25);
}

.right-login {
    background: radial-gradient(123.37% 145% at 29.41% 3.92%, #8338EC 49.11%, #BFA1E9 96.5%);
}

@media (max-width: 768px) {
    .welcome {
        background: linear-gradient(180deg, #FDFBFD 0%, #E3CCFF 55%, #FDFBFD 100%);


    }

    .right-login {
        background: none;
    }

    .signin {
        /* background: white; */
        background: radial-gradient(123.37% 145% at 29.41% 3.92%, #8338EC 49.11%, #BFA1E9 96.5%);
    }
}

.signin {
    background: linear-gradient(90deg, #DECBF8 0%, #BFA1E9 100%);
}